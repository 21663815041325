import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiService } from '../../../SGRE-shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class SavedCartsService {

  private componentSaveCartSubject = new Subject<void>();
  componentSaveCartFunctionCalled$ = this.componentSaveCartSubject.asObservable();
  callcomponentNewCartFunction() {
    this.componentSaveCartSubject.next();
  }

  constructor(
    private apiService: ApiService
  ) { }

  public createCart(paramsObject): Observable<any> {
    return this.apiService.createNewSavedCart(paramsObject);
  }

  public duplicateCart(cartId, paramsObject): Observable<any> {
    return this.apiService.duplicateCart(cartId, paramsObject);
  }

  public deleteCart(cartId): Observable<any> {
    return this.apiService.deleteSavedCart(cartId);
  }

  public getSaveCartFilterList(): Observable<any> {
    return this.apiService.getSaveCartFilterList();
  }
}